<template>
  <Modal v-model="isShowModal" fullscreen footer-hide>
    <div v-if="isShowModal" class="can-pay-root-container">
      <span style="font-size: 32px; font-weight: bold">账户资产充足</span>
      <div class="can-pay-tip">
        <p>🎉恭喜，您的账户资产可以完成支付</p>
      </div>
      <div class="can-pay-row">
        <Button
          size="large"
          type="primary"
          style="width: 200px"
          @click="showPhone"
          >完成支付</Button
        >
        <Button
          size="large"
          type="text"
          style="color: #416895; width: 200px"
          @click="remove"
          >放弃该处理队列</Button
        >
      </div>

      <queue-phone-modal
        ref="queuePhoneModal"
        @next="showCode"
        :dataSource="queueForm"
      />
      <queue-code-modal
        ref="queueCodeModal"
        :loading="loading"
        @next="showComplete"
        :dataSource="queueForm"
      />
      <queue-complete-modal
        ref="queueCompleteModal"
        @close="close"
        :dataSource="queueForm"
      />
    </div>
  </Modal>
</template>

<script>
import { mapActions, mapState, mapGetters } from "vuex";
import QueuePhoneModal from "../Modal/QueuePhoneModal";
import QueueCodeModal from "../Modal/QueueCodeModal";
import QueueCompleteModal from "../Modal/QueueCompleteModal";
export default {
  components: {
    "queue-phone-modal": QueuePhoneModal,
    "queue-code-modal": QueueCodeModal,
    "queue-complete-modal": QueueCompleteModal,
  },
  data() {
    return {
      loading: false,
      isShowModal: false,

      queueForm: null,
    };
  },
  methods: {
    ...mapActions({
      queueRePayAction: "queueRePay",
      queueRemoveQueueAction: "queueRemoveQueue",
    }),
    open(item) {
      this.queueForm = item;
      this.isShowModal = true;
    },
    showPhone() {
      this.$refs.queuePhoneModal.open();
    },
    showCode() {
      this.$refs.queuePhoneModal.close();
      this.$refs.queueCodeModal.open();
    },
    showComplete(pwd) {
      let params = {
        employer_id: localStorage.getItem("employer_id"),
        queue_id: this.queueForm.queue_id,
        pwd: pwd,
      };
      this.loading = true;
      this.queueRePayAction(params)
        .then((res) => {
          this.$refs.queueCodeModal.close();
          this.$refs.queueCompleteModal.open(res);
        })
        .catch((error) => {
          this.$Message.error(error);
        })
        .finally(() => {
          this.loading = false;
        });
    },
    close() {
      this.$parent.$emit("close");
      this.$emit("close");
      this.isShowModal = false;
    },
    remove() {
      this.queueRemoveQueueAction({
        employer_id: localStorage.getItem("employer_id"),
        queue_id: this.queueForm.queue_id,
      })
        .then((res) => {
          this.close();
        })
        .catch((error) => {
          this.$Message.error(error);
        });
    },
  },
};
</script>

<style scoped>
.can-pay-root-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 200px;
}
.can-pay-tip {
  display: flex;
  flex-direction: column;
  border-radius: 10px;
  padding: 20px;
  max-width: 600px;
  background-color: #edead6;
  margin-top: 30px;
}
.can-pay-row {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 100px;
}
</style>
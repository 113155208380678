<template>
  <my-drawer ref="payItemDrawer" title="编辑信息" :transfer="false">
    <template v-slot:main>
      <div style="display: flex; flex-direction: column">
        <my-input class="employer-form-item" title="姓名" v-model="payItemForm.client_name" />

        <my-input class="employer-form-item" title="电话" v-model="payItemForm.client_phone" />

        <my-input class="employer-form-item" title="身份证号" v-model="payItemForm.client_idnum" />

        <my-input class="employer-form-item" title="位置" v-model="payItemForm.position" />

        <my-input class="employer-form-item" title="银行名称" v-model="payItemForm.bank_name" />

        <my-input class="employer-form-item" title="银行卡号" v-model="payItemForm.bank_num" />

        <my-input class="employer-form-item" title="开户行" v-model="payItemForm.bank_location" />

        <my-input class="employer-form-item" title="金额" v-model="payItemForm.amount" />
      </div>
    </template>
    <template v-slot:footer>
      <Button size="large" type="primary" style="width: 100px" @click="submit">保存</Button>
    </template>
  </my-drawer>
</template>

<script>
import MyInput from "../Form/MyInput";
import MyDrawer from "../MyDrawer";
import { mapActions } from "vuex";
export default {
  components: {
    "my-drawer": MyDrawer,
    "my-input": MyInput,
  },
  data() {
    return {
      payItemForm: {
        employer_id: 0,
        client_name: "",
        client_phone: "",
        client_idnum: "",
        position: "",
        bank_name: "",
        bank_num: "",
        bank_location: "",
        amount: 0,
      },
    };
  },
  methods: {
    ...mapActions({
      queueCheckEmployeeAction: "queueCheckEmployee",
    }),
    open(data) {
      this.payItemForm = {
        employer_id: localStorage.getItem("employer_id"),
        client_name: data.client_name,
        client_phone: data.client_phone,
        client_idnum: data.client_idnum,
        position: data.position,
        bank_name: data.bank_name,
        bank_num: data.bank_num,
        bank_location: data.bank_location,
        amount: data.amount,
      };
      this.$refs.payItemDrawer.open();
    },
    submit() {
      if (this.payItemForm.client_name == "") {
        this.$message.error("请输入姓名");
        return;
      }
      if (this.payItemForm.client_phone == "") {
        this.$message.error("请输入电话");
        return;
      }
      if (this.payItemForm.client_idnum == "") {
        this.$message.error("请输入身份证号");
        return;
      }
      if (this.payItemForm.position == "") {
        this.$message.error("请输入位置");
        return;
      }
      if (this.payItemForm.bank_name == "") {
        this.$message.error("请输入银行名称");
        return;
      }
      if (this.payItemForm.bank_num == "") {
        this.$message.error("请输入银行卡号");
        return;
      }
      if (this.payItemForm.bank_location == "") {
        this.$message.error("请输入开户行");
        return;
      }
      if (this.payItemForm.amount == "") {
        this.$message.error("请输入金额");
        return;
      }
      this.queueCheckEmployeeAction(this.payItemForm)
        .then((res) => {
          this.$Message.success("信息修改成功");
          this.$emit(
            "update",
            Object.assign(this.payItemForm, {
              client_user_id: res.client_user_id,
            })
          );
          this.$refs.payItemDrawer.close();
        })
        .catch((error) => {
          this.$Message.error(error);
        });
    },
  },
};
</script>

<style scoped>
</style>
<template>
  <Modal v-model="isShowModal" footer-hide :closable="false">
    <div v-if="isShowModal" class="queue-modal-root-container">
      <!-- <my-avatar :name="card.name" :img_url="card.img_url" :size="50" style="margin-right: 10px;"></my-avatar>
      <span style="font-size:15px;font-weight:bold;margin-top:20px;">{{card.name}}</span> -->
      <fee
        style="margin-top: 20px"
        :amount="totalAmount"
        :primarySize="23"
        :secondarySize="18"
      />
      <Divider />
      <span style="font-size: 23px; font-weight: bold; margin-bottom: 20px"
        >🎉交易已提交银行</span
      >
      <span style="font-size: 15px; margin-bottom: 50px"
        >预计3分钟银行完成出账操作</span
      >

      <div style="font-size: 20px; font-weight: bold; margin-bottom: 20px">
        成功{{ response.success_list.length }}条
      </div>
      <div
        v-for="(item, index) in response.success_list"
        :key="'success-' + index"
        style="font-size: 16px; font-weight: bold; margin-bottom: 10px"
      >
        {{ item }}
      </div>
      <div
        style="
          font-size: 20px;
          font-weight: bold;
          margin-top: 10px;
          margin-bottom: 20px;
        "
      >
        失败{{ response.error_list.length }}条
      </div>
      <div
        v-for="(item, index) in response.error_list"
        :key="'error-' + index"
        style="font-size: 16px; font-weight: bold; margin-bottom: 10px"
      >
        {{ item }}
      </div>

      <Button
        size="large"
        shape="circle"
        type="primary"
        style="width: 150px; margin-top: 30px"
        @click="$emit('close')"
        >关闭</Button
      >
    </div>
  </Modal>
</template>

<script>
import { mapActions, mapState, mapGetters } from "vuex";
import Fee from "../../../components/Fee";
import MyAvatar from "../../../components/MyAvatar";
import * as icon from "../../../common/icon";
export default {
  props: {
    dataSource: Object,
  },
  components: {
    fee: Fee,
    "my-avatar": MyAvatar,
  },
  data() {
    return {
      icons: icon,
      isShowModal: false,

      // card: {
      //   name: "",
      //   img_url: null,
      // },
      response: {},
      payList: [],
    };
  },
  computed: {
    totalAmount() {
      let result = 0;
      for (let item of this.payList) {
        result += item.amount;
      }
      return result;
    },
  },
  methods: {
    ...mapActions({
      planGetPayListAction: "planGetPayList",
      assetGetCardDetailAction: "assetGetCardDetail",
    }),
    open(response, form) {
      this.response = response;
      this.isShowModal = true;

      if (form) {
        this.payList = form.pay_item_arr;
      } else {
        this.planGetPayListAction({
          employer_id: localStorage.getItem("employer_id"),
          pay_plan_id_arr: this.dataSource.pay_plan_id_arr,
        })
          .then((res) => {
            this.payList = res;
          })
          .catch((error) => {
            this.$Message.error(error);
          });
      }
    },
    close() {
      this.$emit("close");
      this.isShowModal = false;
    },
  },
};
</script>

<style scoped>
.queue-modal-root-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 50px 100px;
}
.queue-modal-column {
  display: flex;
  flex-direction: column;
  align-self: flex-start;
}
.queue-modal-row {
  display: flex;
  flex-direction: row;
  align-items: center;
}
</style>
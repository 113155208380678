<template>
  <div
    :class="[
      'queue-card-item-container',
      isSelected ? 'queue-card-item-border' : null,
    ]"
    :style="[canClick ? { cursor: 'pointer' } : {}]"
  >
    <my-avatar
      :size="40"
      :name="dataSource.company_name"
      :img_url="dataSource.img_url"
    ></my-avatar>
    <div class="queue-card-main-container">
      <div style="font-size: 15px; font-weight: bold; margin-bottom: 5px">
        {{ dataSource.company_name }}
      </div>
      <div style="font-size: 12px; color: #7d7d7d">{{ dataSource.issuer }}</div>
    </div>
    <div class="queue-card-right-container">
      <fee
        style="margin-bottom: 5px"
        :amount="dataSource.salary_amount"
        :primarySize="15"
        :secondarySize="12"
      />
      <div class="queue-card-row">
        <span style="font-size: 12px">已冻结:</span>
        <fee
          :amount="dataSource.frozen_amount"
          :primarySize="12"
          :secondarySize="12"
        />
      </div>
      <div class="queue-card-row">
        <span style="font-size: 12px">服务费:</span>
        <fee
          :amount="dataSource.service_amount"
          :primarySize="12"
          :secondarySize="12"
        />
      </div>
    </div>
    <Icon
      type="ios-arrow-forward"
      :size="25"
      style="margin-left: 15px"
      v-if="canClick"
    />
  </div>
</template>

<script>
import * as icon from "../../../common/icon";
import Fee from "../../../components/Fee";
import MyAvatar from "../../../components/MyAvatar";
export default {
  props: {
    dataSource: Object,
    canClick: {
      type: Boolean,
      default: false,
    },
    isSelected: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    fee: Fee,
    "my-avatar": MyAvatar,
  },
};
</script>

<style scoped>
.queue-card-item-container {
  width: 450px;
  height: 100px;
  background: #f6f7f8;
  border-radius: 8px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 0px 20px;
  box-sizing: content-box;
}
.queue-card-main-container {
  flex: 1;
  display: flex;
  flex-direction: column;
  margin-left: 10px;
}
.queue-card-right-container {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}
.queue-card-row {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.queue-card-item-border {
  border: 3px solid #f8cf7c;
}
</style>
<template>
  <div :class="[
      'fee-root-container',
      feeType == 'out' ? 'fee-out' : '',
      feeType == 'in' ? 'fee-in' : '',
    ]" :style="{ fontSize: primarySize + 'px' }">
    ￥{{ amountFormat.integer }}.
    <span :style="{ fontSize: secondarySize + 'px' }">{{
      amountFormat.decimal
    }}</span>
  </div>
</template>

<script>
export default {
  props: {
    feeType: {
      type: String,
      default: "",
    },
    amount: Number,
    primarySize: Number,
    secondarySize: Number,
  },
  computed: {
    amountFormat: function () {
      let number = this.amount.toFixed(2);
      let arr = number.split(".");
      return {
        integer: arr[0],
        decimal: arr[1],
      };
    },
  },
};
</script>

<style scoped>
.fee-root-container {
  font-weight: bold;
  color: #000000;
}
.fee-out {
  color: #37a320;
}
.fee-in {
  color: #ed4014;
}
</style>
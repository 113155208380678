<template>
  <Modal v-model="isShowModal" fullscreen footer-hide>
    <div v-if="isShowModal" class="can-pay-root-container">
      <span style="font-size:32px;font-weight:bold">您绑定的账户资金不足</span>
      <div class="can-pay-tip">
        <p>👋 我们注意到您选中的账户资产不足，无法立刻完成支付。您可以继续点击下一步，我们会为您将不足的金额部分生成提示看板</p>
      </div>
      <div class="can-pay-row">
        <Button size="large" type="text" style="color:#416895; width:200px;" @click="close">放弃该处理队列</Button>
      </div>
    </div>
  </Modal>
</template>

<script>
import { mapActions } from "vuex";
export default {
  data() {
    return {
      isShowModal: false,

      queue_id: 0,
    };
  },
  methods: {
    ...mapActions({
      queueRemoveQueueAction: "queueRemoveQueue",
    }),
    open(id) {
      this.queue_id = id;
      this.isShowModal = true;
    },
    close() {
      this.queueRemoveQueueAction({
        employer_id: localStorage.getItem("employer_id"),
        queue_id: this.queue_id,
      })
        .then((res) => {
          this.$parent.$emit('close');
          this.isShowModal = false;
        })
        .catch((error) => {
          this.$Message.error(error);
        });
    },
  },
};
</script>

<style scoped>
.can-pay-root-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 200px;
}
.can-pay-tip {
  display: flex;
  flex-direction: column;
  border-radius: 10px;
  padding: 20px;
  max-width: 600px;
  background-color: #ecdbdb;
  margin-top: 30px;
}
.can-pay-row {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 100px;
}
</style>
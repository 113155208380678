<template>
  <Modal v-model="isShowModal" footer-hide :closable="false">
    <div class="resend-queue-success-modal-root-container">
      <fee :amount="total" :primarySize="28" :secondarySize="24" />
      <span style="font-size: 23px; font-weight: bold; margin-top: 20px"
        >创建成功</span
      >
      <Button
        shape="circle"
        type="default"
        style="width: 100px; margin-top: 30px"
        @click="$emit('complete')"
        >关闭</Button
      >
    </div>
  </Modal>
</template>

<script>
import FeeVue from "../../../components/Fee.vue";
export default {
  components: {
    fee: FeeVue,
  },
  data() {
    return {
      isShowModal: false,

      data: {
        pay_item_arr: [],
      },
    };
  },
  computed: {
    total() {
      let salary_amount = 0;
      this.data.pay_item_arr.map((item) => {
        salary_amount += item.amount;
        return item;
      });
      return salary_amount;
    },
  },
  methods: {
    open(data) {
      this.data = data;
      this.isShowModal = true;
    },
    close() {
      this.isShowModal = false;
    },
  },
};
</script>

<style scoped>
.resend-queue-success-modal-root-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 50px;
}
</style>
<template>
  <div class="queue-company-item-root-container">
    <span class="queue-company-name">{{ dataSource.company_name }}</span>
    <div class="queue-company-pay-plan-container">
      <plan-item
        v-for="item in dataSource.pay_plan_list"
        :key="item.id"
        :dataSource="item"
        @click.native="select(item)"
      />
    </div>
  </div>
</template>

<script>
import PlanItem from "../../Plan/Item/PlanItem";
export default {
  props: {
    dataSource: Object,
  },
  components: {
    "plan-item": PlanItem,
  },
  data() {
    return {};
  },
  methods: {
    select(item) {
      item.isSelected = !item.isSelected;
    },
  },
};
</script>

<style scoped>
.queue-company-item-root-container {
  display: flex;
  flex-direction: column;
}
.queue-company-name {
  color: #000000;
  font-size: 24px;
  font-weight: bold;
}
.queue-company-pay-plan-container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}
</style>
<template>
  <div class="queue-step-page-container">
    <Button
      type="primary"
      icon="ios-arrow-back"
      class="back-button"
      @click="$emit('back')"
    ></Button>
    <span style="font-size: 32px; font-weight: bold; margin-bottom: 50px"
      >小计</span
    >

    <queue-asset-item
      v-for="item in summaryList"
      :key="item.company_id"
      :data-source="item"
    />

    <Button
      type="primary"
      @click="next"
      size="large"
      style="width: 300px; margin-top: 50px; flex-shrink: 0"
      >👌 确认完毕，输入密码完成最后支付</Button
    >

    <div style="min-height: 80px"></div>
  </div>
</template>

<script>
import { mapActions, mapState, mapGetters } from "vuex";
import QueueItemCard from "../Item/QueueItemCard";
import AssetCard from "../../Asset/Item/AssetCard";
import Fee from "../../../components/Fee";
import moment from "moment";
import QueueAssetItemVue from "../Item/QueueAssetItem.vue";
export default {
  props: {
    dataSource: Object,
  },
  components: {
    fee: Fee,
    "asset-card": AssetCard,
    "queue-item-card": QueueItemCard,
    "queue-asset-item": QueueAssetItemVue,
  },
  data() {
    return {
      summaryList: [],
      cardList: [],
      payList: [],

      serviceFee: 0,
    };
  },
  mounted() {
    this.queueGetSummaryAction({
      employer_id: localStorage.getItem("employer_id"),
      pay_plan_arr: this.dataSource.pay_plan_arr,
    })
      .then((res) => {
        for (let item of res) {
          for (let pay_plan of item.pay_plan_arr) {
            pay_plan.service_amount = Number(pay_plan.service_amount);
          }
        }
        this.summaryList = res;
      })
      .catch((error) => {
        this.$Message.error(error);
      });
  },
  methods: {
    ...mapActions({
      queueGetSummaryAction: "queueGetSummary",
    }),
    next() {
      let flag = true;
      for (let item of this.summaryList) {
        let total_salary_amount = 0;
        item.pay_plan_arr.map((item) => {
          total_salary_amount += item.salary_amount;
          return item;
        });
        if (total_salary_amount > item.company_salary_amount) {
          flag = false;
        }
      }
      if (flag) {
        this.$parent.$parent.showPhone();
      } else {
        this.$emit("next");
      }
    },
  },
};
</script>

<style scoped>
.queue-step-page-container {
  display: flex;
  flex-direction: column;
  overflow-y: scroll;
}
</style>
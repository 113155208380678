<template>
  <Modal v-model="isShowModal" fullscreen footer-hide>
    <div v-if="isShowModal" class="create-queue-modal-root-container">
      <queue-step1
        v-if="currentPage == 1"
        @next="page1Next"
        :dataSource="queueForm"
      />
      <queue-step2
        v-if="currentPage == 2"
        :dataSource="queueForm"
        @next="page2Next"
        @back="currentPage--"
      />
      <queue-step3
        v-if="currentPage == 3"
        :dataSource="queueForm"
        @close="close"
        @back="currentPage--"
      />

      <queue-phone-modal
        ref="queuePhoneModal"
        @next="showCode"
        :dataSource="queueForm"
      />
      <queue-code-modal
        ref="queueCodeModal"
        :loading="loading"
        @next="showComplete"
        :dataSource="queueForm"
      />
      <re-send-queue-fail-modal
        ref="reSendQueueFailModal"
        @complete="closeFailModal"
      />
      <re-send-queue-success-modal
        ref="reSendQueueSuccessModal"
        @complete="closeSuccessModal"
      />
    </div>
  </Modal>
</template>

<script>
import moment from "moment";
import { mapActions, mapState, mapGetters } from "vuex";
import QueuePhoneModal from "../Modal/QueuePhoneModal";
import QueueCodeModal from "../Modal/QueueCodeModal";
import ReSendQueueStep1 from "../Page/ReSendQueueStep1";
import ReSendQueueStep2 from "../Page/ReSendQueueStep2";
import ReSendQueueStep3 from "../Page/ReSendQueueStep3";
import ReSendQueueSucessModalVue from "./ReSendQueueSucessModal.vue";
import ReSendQueueFailModalVue from "./ReSendQueueFailModal.vue";
export default {
  components: {
    "queue-phone-modal": QueuePhoneModal,
    "queue-code-modal": QueueCodeModal,
    "queue-step1": ReSendQueueStep1,
    "queue-step2": ReSendQueueStep2,
    "queue-step3": ReSendQueueStep3,
    "re-send-queue-fail-modal": ReSendQueueFailModalVue,
    "re-send-queue-success-modal": ReSendQueueSucessModalVue,
  },
  data() {
    return {
      loading: false,
      isShowModal: false,
      currentPage: 1,

      queueForm: {
        error_id: 0,
        employer_id: localStorage.getItem("employer_id"),
        project_id: 0,
        old_pay_item_arr: [],
        new_pay_item_arr: [],
        company_id: 0,
        company_name: "",
        company_contact_id: 0,
        name: "",
        old_name: "",
        type: 1,
        pay_time: new Date(),
        more: "",
        pwd: "",
        temp_save: false,
      },
    };
  },
  methods: {
    ...mapActions({
      queueRePayByManualAction: "queueRePayByManual",
      queueGetErrorPayListAction: "queueGetErrorPayList",
      queueGetDetailByErrorIdAction: "queueGetDetailByErrorId",
      assetIsOpenCardByEmployerIdAction: "assetIsOpenCardByEmployerId",
    }),
    open(id) {
      this.queueForm.error_id = id;
      this.currentPage = 1;

      this.queueGetErrorPayListAction({
        employer_id: localStorage.getItem("employer_id"),
        error_id: this.queueForm.error_id,
      }).then((res) => {
        this.queueForm.old_pay_item_arr = JSON.parse(JSON.stringify(res));
        this.queueForm.new_pay_item_arr = res;
      });

      this.queueGetDetailByErrorIdAction({
        employer_id: localStorage.getItem("employer_id"),
        error_id: this.queueForm.error_id,
      }).then((res) => {
        this.queueForm.name = res.name;
        this.queueForm.type = res.type;
        this.queueForm.more = res.more;
        this.queueForm.old_name = res.name;
        this.queueForm.project_id = res.project_id;
        this.queueForm.company_id = res.company_id;
        this.queueForm.company_name = res.company_name;
        this.queueForm.pay_time = moment(res.pay_time).toDate();
        this.queueForm.company_contact_id = res.company_contact_id;
      });

      this.isShowModal = true;
    },
    close() {
      this.$emit("close");
      this.isShowModal = false;
    },
    page1Next() {
      this.currentPage++;
    },
    page2Next(obj) {
      this.queueForm.name = obj.name;
      this.queueForm.type = obj.type;
      this.queueForm.pay_time = obj.pay_time;
      this.queueForm.more = obj.more;
      this.currentPage++;
    },
    showPhone() {
      this.$refs.queuePhoneModal.open(this.queueForm);
    },
    showCode() {
      this.$refs.queuePhoneModal.close();
      this.$refs.queueCodeModal.open(this.queueForm);
    },
    showComplete(pwd) {
      this.queueForm.pwd = pwd;
      let form = Object.assign(this.queueForm, {
        pay_time: moment(this.queueForm.pay_time).format("YYYY-MM-DD 00:00:00"),
        pay_item_arr: this.queueForm.new_pay_item_arr,
      });
      this.loading = true;
      this.queueRePayByManualAction(form)
        .then((res) => {
          this.$refs.queueCodeModal.close();
          if (res.file_url) {
            this.$refs.reSendQueueFailModal.open({
              pay_item_arr: this.queueForm.new_pay_item_arr,
              file_url: res.file_url,
            });
          } else {
            this.$refs.reSendQueueSuccessModal.open({
              pay_item_arr: this.queueForm.new_pay_item_arr,
            });
          }
        })
        .catch((error) => {
          this.$Message.error(error);
        })
        .finally(() => {
          this.loading = false;
        });
    },
    close() {
      this.isShowModal = false;
      this.$emit("close");
    },
    closeFailModal() {
      this.$refs.reSendQueueFailModal.close();
    },
    closeSuccessModal() {
      this.$refs.reSendQueueSuccessModal.close();
      this.close();
    },
  },
};
</script>

<style scoped>
.create-queue-modal-root-container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100vh;
  padding-top: 50px;
  padding-left: 285px;
  padding-right: 150px;
  background-color: #f9f9f9;
  overflow: hidden;
}
</style>
<template>
  <Modal v-model="isShowModal" fullscreen footer-hide>
    <div v-if="isShowModal" class="pay-finish-root-container">
      <div class="pay-finish-head-container">
        <div class="page-title">已完成的付款</div>
        <!-- <span
          style="
            font-size: 13px;
            font-weight: bold;
            color: #587bea;
            cursor: pointer;
          "
          @click="showTradeModal"
          >全部交易</span
        > -->
      </div>
      <div class="pay-finish-total-container">
        <Input
          v-model="search_content"
          placeholder="处理队列名称"
          style="width: 300px"
        />
        <span style="margin-left: 20px"> 开始时间： </span>
        <DatePicker
          transfer
          type="date"
          v-model="start_time"
          placeholder="选择日期"
          style="width: 200px"
        ></DatePicker>
        <span style="margin-left: 20px"> 结束时间： </span>
        <DatePicker
          transfer
          type="date"
          v-model="end_time"
          placeholder="选择日期"
          style="width: 200px"
        ></DatePicker>

        <Button style="margin-left: 20px" @click="search" type="primary"
          >搜索</Button
        >
        <div style="flex: 1"></div>
        <!-- <fee :amount="totalAmount" :primarySize="23" :secondarySize="23" /> -->
        <span style="font-size: 13px; font-weight: 500; color: #868686"
          >合计：{{ page_data_count }}笔</span
        >
      </div>
      <div class="pay-finish-item-list">
        <queue-item
          v-for="item in queueList"
          :key="item.queue_id"
          :data-source="item"
          @click.native="goDetail(item)"
          @refresh="getList"
        />
      </div>

      <Page
        style="align-self: center; margin: 50px 0"
        :transfer="true"
        :current.sync="current_page_index"
        :total="page_data_count"
        :page-size="page_size"
        @on-change="handleChangePageIndex"
      ></Page>

      <asset-trade-modal ref="assetTradeModal" />
    </div>
  </Modal>
</template>

<script>
import moment from "moment";
import { mapActions } from "vuex";
import Fee from "../../../components/Fee";
import QueueItem from "../Item/QueueItem";
import AssetTradeModal from "../../Asset/Modal/AssetTradeModal";
export default {
  components: {
    fee: Fee,
    "queue-item": QueueItem,
    "asset-trade-modal": AssetTradeModal,
  },
  data() {
    return {
      isShowModal: false,
      // 分页当前页面
      current_page_index: 1,

      search_content: "",
      start_time: null,
      end_time: null,

      page_index: 0,
      page_size: 5,
      page_data_count: 0,

      queueList: [],
    };
  },
  mounted() {},
  computed: {
    totalAmount: function () {
      let total_amount = 0;
      for (let item of this.queueList) {
        total_amount += item.salary_amount;
      }
      return total_amount;
    },
  },
  methods: {
    ...mapActions({
      queueGetDoneListAction: "queueGetDoneList",
    }),
    handleChangePageIndex(page) {
      if (page != this.page_index + 1) {
        this.page_index = page - 1;
        this.getList();
      }
    },
    goDetail(item) {
      this.$router.push({
        name: "queue-detail",
        params: {
          id: item.queue_id,
        },
      });
    },
    open() {
      this.isShowModal = true;
      this.search();
    },
    search() {
      this.page_index = 0;
      this.current_page_index = this.page_index + 1;
      this.getList();
    },
    getList() {
      this.queueGetDoneListAction({
        employer_id: localStorage.getItem("employer_id"),
        page_index: this.page_index,
        page_size: this.page_size,
        search_content: this.search_content,
        start_time: moment(this.start_time).format("YYYY/MM/DD 00:00:00"),
        end_time: moment(this.end_time).format("YYYY/MM/DD 23:59:59"),
      })
        .then((res) => {
          for (let item of res.data) {
            for (let failItem of item.fail_list) {
              if (failItem.name && !failItem.client_user_name) {
                failItem.client_user_name = failItem.name;
              }
            }
            item.pay_time = moment(item.pay_time).format("YYYY/MM/DD HH:mm");
          }
          this.queueList = res.data;
          this.page_data_count = res.total_count;
        })
        .catch((error) => {
          console.error(error);
          this.$Message.error(error);
        });
    },
    showTradeModal() {
      this.$refs.assetTradeModal.open();
    },
  },
};
</script>

<style scoped>
.pay-finish-root-container {
  display: flex;
  flex-direction: column;
  width: 100vw;
  height: 100vh;
  padding-top: 100px;
  padding-left: 285px;
  padding-right: 150px;
  overflow-y: scroll;
  background-color: #f9f9f9;
}
.pay-finish-head-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-end;
}
.pay-finish-total-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 80px;
  margin-bottom: 40px;
}
.pay-finish-item-list {
  background-color: #ffffff;
}
</style>
<template>
  <div class="queue-asset-item-root-container">
    <Row style="margin-bottom: 15px">
      <Col span="9"
        ><div>{{ dataSource.company_name }}</div></Col
      >
      <Col span="5" offset="5"><div style="text-align: right">服务费</div></Col>
      <Col span="5"><div style="text-align: right">金额</div></Col>
    </Row>
    <Row
      v-for="(item, index) in dataSource.pay_plan_arr"
      :key="index"
      style="margin-bottom: 10px"
    >
      <Col span="9"
        ><div>{{ item.pay_plan_name }}</div></Col
      >
      <Col span="5"
        ><div style="text-align: right">全部{{ item.detail_count }}笔</div></Col
      >
      <Col span="5"
        ><div style="text-align: right">
          <fee
            :amount="item.service_amount"
            :primarySize="18"
            :secondarySize="18"
          /></div
      ></Col>
      <Col span="5"
        ><div style="text-align: right">
          <fee
            :amount="item.salary_amount"
            :primarySize="18"
            :secondarySize="18"
          /></div
      ></Col>
    </Row>
    <Divider />
    <Row style="margin-bottom: 10px">
      <Col span="9"
        ><div style="text-align: right; font-size: 23px">合计</div></Col
      >
      <Col span="5"
        ><div style="text-align: right; font-size: 23px">
          全部{{ total.count }}笔
        </div></Col
      >
      <Col span="5"
        ><div style="text-align: right">
          <fee
            :amount="total.service_amount"
            :primarySize="23"
            :secondarySize="23"
          /></div
      ></Col>
      <Col span="5"
        ><div style="text-align: right">
          <fee
            :amount="total.salary_amount"
            :primarySize="23"
            :secondarySize="23"
          /></div
      ></Col>
    </Row>
    <Row style="margin-bottom: 15px">
      <Col span="9"
        ><div style="text-align: right; font-size: 23px">当前余额</div></Col
      >
      <Col span="5" offset="5"
        ><div style="text-align: right">
          <fee
            :amount="dataSource.company_service_amount"
            :primarySize="23"
            :secondarySize="23"
          /></div
      ></Col>
      <Col span="5"
        ><div style="text-align: right">
          <fee
            :amount="dataSource.company_salary_amount"
            :primarySize="23"
            :secondarySize="23"
          /></div
      ></Col>
    </Row>
    <Row style="margin-bottom: 15px">
      <Col span="9"
        ><div style="text-align: right; font-size: 23px">扣款后余额</div></Col
      >
      <Col span="5" offset="5"
        ><div style="text-align: right">
          <fee2
            :amount="dataSource.company_service_amount - total.service_amount"
            :primarySize="23"
            :secondarySize="23"
          /></div
      ></Col>
      <Col span="5"
        ><div style="text-align: right">
          <fee2
            :amount="dataSource.company_salary_amount - total.salary_amount"
            :primarySize="23"
            :secondarySize="23"
          /></div
      ></Col>
    </Row>
  </div>
</template>

<script>
import FeeVue from "../../../components/Fee.vue";
import FeeVue2 from "../../../components/Fee2.vue";
export default {
  components: {
    fee: FeeVue,
    fee2: FeeVue2,
  },
  props: {
    dataSource: Object,
  },
  computed: {
    total() {
      let count = 0,
        service_amount = 0,
        salary_amount = 0;
      this.dataSource.pay_plan_arr.map((item) => {
        count += item.detail_count;
        service_amount += item.service_amount;
        salary_amount += item.salary_amount;
        return item;
      });
      return {
        count,
        service_amount,
        salary_amount,
      };
    },
  },
};
</script>

<style scoped>
.queue-asset-item-root-container {
  display: flex;
  flex-direction: column;
  flex-shrink: 0;
  padding: 20px;
  border-radius: 10px;
  background-color: #fff;
  margin-top: 50px;
  margin-bottom: 20px;

  font-size: 15px;
  font-weight: bold;
}

div {
  min-height: 1px;
}
</style>
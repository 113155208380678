<template>
  <Modal v-model="isShowModal" fullscreen footer-hide>
    <div class="plan-record-modal-root-container" v-if="isShowModal">
      <div class="page-title">接收到的付款计划</div>

      <div class="plan-record-modal-list-container">
        <plan-item
          style="margin: 10px"
          v-for="item in plan_list"
          :key="item.pay_plan_id"
          :can-show-modal="true"
          :data-source="item"
        />
      </div>
    </div>
  </Modal>
</template>

<script>
import PlanItem from "../../Plan/Item/PlanItem";
import { mapActions } from "vuex";
export default {
  components: {
    "plan-item": PlanItem,
  },
  data() {
    return {
      isShowModal: false,

      currentIndex: 0,
      tabs: [
        {
          name: "等待支付的",
        },
        {
          name: "已被支付的",
        },
      ],
      plan_list: [],
    };
  },
  methods: {
    ...mapActions({
      planGetListAction: "planGetList",
    }),
    open() {
      this.isShowModal = true;
      this.getList(2);
    },
    getList(state) {
      this.plan_list = [];
      this.planGetListAction({
        employer_id: localStorage.getItem("employer_id"),
        state: state,
        orderby_type: 1,
      })
        .then((res) => {
          for (let item of res) {
            item.isValid = true;
            item.isShowBack = true;
          }

          this.plan_list = res;
        })
        .catch((error) => {
          this.$Message.error(error);
        });
    },
  },
};
</script>

<style scoped>
.plan-record-modal-root-container {
  display: flex;
  flex-direction: column;
  padding-top: 100px;
  padding-left: 285px;
  padding-right: 150px;
}
.plan-record-modal-list-container {
  display: flex;
  flex-direction: row;
  margin-top: 50px;
  overflow-x: scroll;
}
</style>
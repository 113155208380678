<template>
  <Modal v-model="isShowModal" fullscreen footer-hide>
    <div v-if="isShowModal" class="create-queue-modal-root-container">
      <queue-step1
        v-if="currentPage == 1"
        @next="page1Next"
        :dataSource="queueForm"
      />
      <queue-step2
        v-if="currentPage == 2"
        :dataSource="queueForm"
        @next="page2Next"
        @back="currentPage--"
      />
      <queue-step3
        v-if="currentPage == 3"
        :dataSource="queueForm"
        @close="close"
        @next="page3Next"
        @back="currentPage--"
      />
      <queue-step5
        v-if="currentPage == 4"
        @back="currentPage = 3"
        @close="close"
        @save="save"
      />

      <queue-phone-modal
        ref="queuePhoneModal"
        @next="showCode"
        :dataSource="queueForm"
      />
      <queue-code-modal
        ref="queueCodeModal"
        :loading="loading"
        @next="showComplete"
        :dataSource="queueForm"
      />
      <queue-complete-modal
        ref="queueCompleteModal"
        @close="close"
        :dataSource="queueForm"
      />
    </div>
  </Modal>
</template>

<script>
import { mapActions, mapState, mapGetters } from "vuex";
import QueuePhoneModal from "../Modal/QueuePhoneModal";
import QueueCodeModal from "../Modal/QueueCodeModal";
import QueueCompleteModal from "../Modal/QueueCompleteModal";
import NewQueueStep1 from "../Page/NewQueueStep1";
import NewQueueStep2 from "../Page/NewQueueStep2";
import NewQueueStep3 from "../Page/NewQueueStep3";
import QueueStep5 from "../Page/QueueStep5";
import moment from "moment";
export default {
  components: {
    "queue-phone-modal": QueuePhoneModal,
    "queue-code-modal": QueueCodeModal,
    "queue-complete-modal": QueueCompleteModal,
    "queue-step1": NewQueueStep1,
    "queue-step2": NewQueueStep2,
    "queue-step3": NewQueueStep3,
    "queue-step5": QueueStep5,
  },
  data() {
    return {
      loading: false,
      isShowModal: false,
      currentPage: 1,

      queueForm: {
        employer_id: localStorage.getItem("employer_id"),
        pay_plan_arr: [],
        pay_plan_id_arr: [],
        info_arr: [],
        type: 1,
        company_contact_id: 0,
        pay_time: new Date(),
        more: "",
        pwd: "",
        temp_save: false,
      },
    };
  },
  methods: {
    ...mapActions({
      queueCreateAction: "queueCreate",
    }),
    open() {
      this.currentPage = 1;
      this.isShowModal = true;
    },
    close() {
      this.$emit("close");
      this.isShowModal = false;
    },
    page1Next(obj) {
      this.queueForm.pay_plan_arr = obj.pay_plan_arr;
      this.queueForm.pay_plan_id_arr = obj.pay_plan_id_arr;
      this.currentPage++;
    },
    page2Next(obj) {
      this.queueForm.company_contact_id = obj.company_contact_id;
      this.queueForm.info_arr = obj.info_arr;
      this.queueForm.type = obj.type;
      this.queueForm.pay_time = obj.pay_time;
      this.queueForm.more = obj.more;
      this.currentPage++;
    },
    page3Next() {
      this.queueForm.temp_save = true;
      this.currentPage++;
    },
    showPhone() {
      this.$refs.queuePhoneModal.open();
    },
    showCode() {
      this.$refs.queuePhoneModal.close();
      this.$refs.queueCodeModal.open();
    },
    showComplete(pwd) {
      this.queueForm.pwd = pwd;
      let form = Object.assign(this.queueForm, {
        pay_time: moment(this.queueForm.pay_time).format("YYYY-MM-DD 00:00:00"),
      });
      this.loading = true;
      this.queueCreateAction(form)
        .then((res) => {
          this.$refs.queueCodeModal.close();
          this.$refs.queueCompleteModal.open(res);
        })
        .catch((error) => {
          this.$Message.error(error);
        })
        .finally(() => {
          this.loading = false;
        });
    },
    save() {
      let form = Object.assign(this.queueForm, {
        pay_time: moment(this.queueForm.pay_time).format("YYYY-MM-DD 00:00:00"),
      });
      this.queueCreateAction(form)
        .then((res) => {
          this.close();
        })
        .catch((error) => {
          this.$Message.error(error);
        });
    },
    close() {
      this.isShowModal = false;
      this.$emit("close");
    },
  },
};
</script>

<style scoped>
.create-queue-modal-root-container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100vh;
  padding-top: 50px;
  padding-left: 285px;
  padding-right: 150px;
  background-color: #f9f9f9;
  overflow: hidden;
}
</style>
<template>
  <div class="queue-step-root-container">
    <span style="font-size: 32px; font-weight: bold; margin-bottom: 20px">选择接收到的付款计划</span>
    <span style="font-size: 13px; font-weight: bold; margin-bottom: 30px">已选中{{ selectedCount }}个</span>

    <queue-company-item style="margin-bottom: 80px" v-for="(item, index) in companyList" :data-source="item"
      :key="index" />

    <div class="tools-container">
      <Button type="primary" @click="next" size="large">确认无误，下一步</Button>
    </div>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import moment from "moment";
import QueueCompanyItemVue from "../Item/QueueCompanyItem.vue";
export default {
  props: {
    dataSource: Object,
  },
  components: {
    "queue-company-item": QueueCompanyItemVue,
  },
  data() {
    return {
      company_id: 0,
      companyList: [],
    };
  },
  computed: {
    selectedCount() {
      let count = 0;
      for (let company of this.companyList) {
        for (let item of company.pay_plan_list) {
          if (item.isSelected) {
            count += 1;
          }
        }
      }
      return count;
    },
  },
  mounted() {
    this.getPlanList();
  },
  methods: {
    ...mapActions({
      queueGetPayPlanListByEmployerIdAction: "queueGetPayPlanListByEmployerId",
      planGetListAction: "planGetList",
    }),
    getPlanList() {
      this.queueGetPayPlanListByEmployerIdAction({
        employer_id: localStorage.getItem("employer_id"),
      })
        .then((res) => {
          for (let company of res) {
            for (let item of company.pay_plan_list) {
              item.isValid = true;
              item.isSelected = false;
            }
          }
          this.companyList = res;
        })
        .catch((error) => {
          this.$Message.error(error);
        });
    },
    select(item) {
      item.isSelected = !item.isSelected;
    },
    next(item) {
      let pay_plan_arr = [];
      let pay_plan_id_arr = [];
      for (let company of this.companyList) {
        for (let item of company.pay_plan_list) {
          if (item.isSelected) {
            pay_plan_arr.push({
              pay_plan_name: item.name,
              queue_name: item.name,
              pay_plan_id: item.pay_plan_id,
              company_id: item.company_id,
            });
            pay_plan_id_arr.push(item.pay_plan_id);
          }
        }
      }

      if (pay_plan_arr.length == 0) {
        this.$Message.error("请选择付款计划");
        return;
      }
      this.$emit("next", {
        pay_plan_arr: pay_plan_arr,
        pay_plan_id_arr: pay_plan_id_arr,
      });
    },
  },
};
</script>

<style scoped>
.queue-step-root-container {
  display: flex;
  flex-direction: column;
  overflow-y: scroll;
}
</style>
<template>
  <div class="pay-list-root-container">
    <div class="pay-list-header-container">
      <span style="width: 40%; text-align: center; font-weight: bold"
        >姓名</span
      >
      <span style="width: 25%; text-align: center; font-weight: bold"
        >银行卡</span
      >
      <span style="width: 25%; text-align: center; font-weight: bold"
        >金额</span
      >
      <span style="width: 10%; text-align: center; font-weight: bold"
        >操作</span
      >
    </div>
    <div class="pay-item-container">
      <pay-item
        v-for="(item, index) in dataSource"
        :key="index"
        :data-source="item"
        @edit="
          () => {
            edit(item, index);
          }
        "
      />
    </div>
    <payitem-drawer ref="payItemDrawer" @update="update" />
  </div>
</template>

<script>
import ReSendPayItem from "../Item/ReSendPayItem";
import PayItemDrawer from "../Drawer/PayItemDrawer";
export default {
  props: {
    dataSource: Array,
  },
  components: {
    "pay-item": ReSendPayItem,
    "payitem-drawer": PayItemDrawer,
  },
  data() {
    return {
      currentIndex: 0,
    };
  },
  methods: {
    edit(item, index) {
      this.currentIndex = index;
      this.$refs.payItemDrawer.open(item);
    },
    update(data) {
      this.dataSource[this.currentIndex].client_user_id = data.client_user_id;
      this.dataSource[this.currentIndex].client_name = data.client_name;
      this.dataSource[this.currentIndex].client_phone = data.client_phone;
      this.dataSource[this.currentIndex].client_idnum = data.client_idnum;
      this.dataSource[this.currentIndex].position = data.position;
      this.dataSource[this.currentIndex].bank_name = data.bank_name;
      this.dataSource[this.currentIndex].bank_num = data.bank_num;
      this.dataSource[this.currentIndex].bank_location = data.bank_location;
      this.dataSource[this.currentIndex].amount = Number(data.amount);
    },
  },
};
</script>

<style>
.pay-list-root-container {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  overflow: hidden;
  background-color: #fff;
  border-radius: 10px;
  margin-top: 30px;
  padding-bottom: 10px;
}
.pay-list-header-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 50px;
  min-height: 50px;
}
.pay-item-container {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  overflow-y: scroll;
}
</style>
<template>
  <Modal v-model="isShowModal" footer-hide :closable="false">
    <div v-if="isShowModal" class="queue-modal-root-container">
      <!-- <my-avatar :name="card.name" :img_url="card.img_url" :size="50" style="margin-right: 10px;"></my-avatar>
      <span style="font-size:15px;font-weight:bold;margin-top:20px;">{{card.name}}</span> -->
      <fee
        style="margin-top: 20px"
        :amount="totalAmount"
        :primarySize="23"
        :secondarySize="18"
      />
      <Divider />
      <div class="queue-modal-column" style="margin-bottom: 30px">
        <span style="font-size: 23px; font-weight: bold; margin-bottom: 20px"
          >输入支付密码</span
        >
        <Input v-model="code" size="large" placeholder="8位支付密码" />
      </div>
      <Button
        :loading="loading"
        size="large"
        shape="circle"
        type="primary"
        style="width: 150px; margin-top: 30px"
        @click="next"
        >确定</Button
      >
    </div>
  </Modal>
</template>

<script>
import { mapActions, mapState, mapGetters } from "vuex";
import Fee from "../../../components/Fee";
import MyAvatar from "../../../components/MyAvatar";
import * as icon from "../../../common/icon";
export default {
  props: {
    dataSource: Object,
    loading:Boolean,
  },
  components: {
    fee: Fee,
    "my-avatar": MyAvatar,
  },
  data() {
    return {
      icons: icon,
      isShowModal: false,
      code: "",
      card: {
        name: "",
        img_url: null,
      },
      payList: [],
    };
  },
  computed: {
    totalAmount() {
      let result = 0;
      for (let item of this.payList) {
        result += item.amount;
      }
      return result;
    },
  },
  methods: {
    ...mapActions({
      planGetPayListAction: "planGetPayList",
    }),
    open(form) {
      this.isShowModal = true;

      if (form) {
        this.payList = form.new_pay_item_arr;
      } else {
        this.planGetPayListAction({
          employer_id: localStorage.getItem("employer_id"),
          pay_plan_id_arr: this.dataSource.pay_plan_id_arr,
        })
          .then((res) => {
            this.payList = res;
          })
          .catch((error) => {
            this.$Message.error(error);
          });
      }
    },
    close() {
      this.isShowModal = false;
    },
    next() {
      this.$emit("next", this.code);
    },
  },
};
</script>

<style scoped>
.queue-modal-root-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 50px 100px;
}
.queue-modal-column {
  display: flex;
  flex-direction: column;
  align-self: flex-start;
}
.queue-modal-row {
  display: flex;
  flex-direction: row;
  align-items: center;
}
</style>
<template>
  <div class="queue-event-item-root-container">
    <div
      v-if="dataSource.type == 1"
      style="display: flex; flex-direction: column"
    >
      <span style="font-size: 15px; font-weight: 500; color: #292929"
        >#{{ dataSource.name }}#的未通过人员列表</span
      >
      <span
        style="
          font-size: 12px;
          font-weight: 500;
          color: #5e5e5e;
          margin-top: 10px;
        "
        >这些伙伴未能通过付款校验,请您转为线下处理</span
      >
      <avatar-list
        :member-list="dataSource.error_info"
        :max-count="7"
        style="margin-top: 20px"
      />
    </div>
    <div v-else style="display: flex; flex-direction: column">
      <span
        style="
          font-size: 15px;
          font-weight: 500;
          color: #292929;
          margin-bottom: 10px;
        "
        >#{{ dataSource.name }}#的罗格错误</span
      >
      <span
        v-for="(item, index) in dataSource.error_info"
        :key="index"
        style="font-size: 12px; font-weight: 500; color: #5e5e5e"
      >
        {{ item.msg }}
      </span>
    </div>
    <div v-if="dataSource.type == 1" class="queue-event-item-btn-container">
      <div
        class="queue-event-item-resend"
        @click="resend"
        v-if="!dataSource.invalid"
      >
        重新发送
      </div>
      <div
        class="queue-event-item-export"
        style="margin-left: 15px"
        @click="getErrorExcel"
      >
        导出Excel
      </div>
    </div>
    <div v-else class="queue-event-item-btn-container">
      <Upload
        v-if="showReUpload"
        :show-upload-list="false"
        :action="uploadPath"
        :before-upload="handleBeforeUpload"
        :on-success="uploadSuccess"
        :data="uploadParam"
        :format="['zip']"
        :max-size="30000"
        :on-format-error="handleFormatError"
        :on-exceeded-size="handleMaxSize"
      >
        <Button :loading="uploadLoading" type="primary">重新提交材料</Button>
      </Upload>
      <!--<Button type="primary" style="margin-left:10px;"
          >重新上传</Button
        > -->
    </div>
    <Icon
      type="md-close"
      class="queue-event-item-close"
      size="24"
      @click="remove"
    />

    <delete-modal ref="deleteModal" @confirm="confirm" />
    <resend-queue-modal ref="resendQueueModal" @close="close" />
  </div>
</template>

<script>
import { mapActions } from "vuex";
import AvatarList from "../../../components/List/AvatarList";
import DeleteModal from "../../../components/Modal/DeleteModal";
import ReSendQueueModal from "../Modal/ReSendQueueModal";
import { apiHost } from "../../../common/env";
export default {
  components: {
    "avatar-list": AvatarList,
    "delete-modal": DeleteModal,
    "resend-queue-modal": ReSendQueueModal,
  },
  props: {
    dataSource: Object,
  },
  data() {
    return {
      uploadPath: apiHost + "pay_plan/reUploadZip",
      uploadParam: {},
      uploadLoading: false,
    };
  },
  mounted() {
    let token = localStorage.getItem("token");
    this.uploadParam = { token: token, queue_id: this.dataSource.queue_id };
  },
  computed: {
    showReUpload() {
      let result = false;
      for (let item of this.dataSource.error_info) {
        if (item.type == 1) {
          result = true;
          break;
        }
      }
      return result;
    },
  },
  methods: {
    ...mapActions({
      queueDelErrorAction: "queueDelError",
      queueGetErrorExcelAction: "queueGetErrorExcel",
    }),
    getErrorExcel() {
      this.queueGetErrorExcelAction({
        employer_id: localStorage.getItem("employer_id"),
        queue_id: this.dataSource.queue_id,
      })
        .then((res) => {
          window.open(res.url);
        })
        .catch((error) => {
          this.$Message.error(error);
        });
    },
    remove() {
      this.$refs.deleteModal.open();
    },
    resend() {
      this.$refs.resendQueueModal.open(this.dataSource.error_id);
    },
    confirm(dataSource, finish) {
      this.queueDelErrorAction({
        employer_id: localStorage.getItem("employer_id"),
        error_id: this.dataSource.error_id,
      })
        .then((res) => {
          this.$emit("refresh-error-list");
          finish();
        })
        .catch((error) => {
          this.$Message.error(error);
          finish();
        });
    },
    close() {
      this.$emit("refresh-queue-list");
      this.$emit("refresh-error-list");
    },
    handleBeforeUpload() {
      this.uploadLoading = true;
      return true;
    },
    handleFormatError(file) {
      this.uploadLoading = false;
      this.$Notice.warning({
        title: "文件类型不正确",
        desc: "只能上传Zip",
      });
    },
    handleMaxSize(file) {
      this.uploadLoading = false;
      this.$Notice.warning({
        title: "文件过大",
        desc: "不得大于30M",
      });
    },
    uploadSuccess(res, file, fileList) {
      if(res.res_code != 1){
        this.$Message.error(res.msg);
      }
      this.uploadLoading = false;
    },
  },
};
</script>

<style scoped>
.queue-event-item-root-container {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 355px;
  height: 210px;
  padding: 15px;
  border-radius: 8px;
  background-color: #ffffff;
  margin-right: 20px;
}
.queue-event-item-btn-container {
  display: flex;
  flex-direction: row;
  align-self: flex-end;
}
.queue-event-item-export {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 5px 15px;
  border-radius: 15px;
  width: 100px;
  color: #ffffff;
  background-color: #898989;
  cursor: pointer;
}
.queue-event-item-resend {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 5px 15px;
  border-radius: 15px;
  width: 100px;
  color: #ffffff;
  background-color: #19be6b;
  cursor: pointer;
}
.queue-event-item-close {
  position: absolute;
  top: 5px;
  right: 5px;
  cursor: pointer;
}
</style>
<template>
  <div class="queue-step-page-container">
    <div class="queue-step-header-container">
      <div class="queue-step-title-container">
        <span style="font-size: 32px; font-weight: bold">全部待支付的列表</span>
      </div>
      <div class="queue-step-title-container" style="align-items: flex-end">
        <fee :amount="totalAmount" :primarySize="32" :secondarySize="28" />
        <span style="font-size: 13px; font-weight: bold; margin-top: 10px"
          >{{ dataSource.new_pay_item_arr.length }}笔，合计金额</span
        >
      </div>
    </div>

    <pay-list :dataSource="dataSource.new_pay_item_arr" />

    <div>
      <Button
        type="primary"
        style="width: 200px; margin-top: 50px"
        @click="next"
        size="large"
        >确认无误，下一步</Button
      >
      <Button
        type="success"
        style="width: 200px; margin-top: 50px; margin-left: 50px"
        @click="syncData"
        size="large"
        >同步信息到罗格</Button
      >
    </div>

    <div style="height: 50px; width: 1px"></div>
  </div>
</template>

<script>
import { mapActions, mapState, mapGetters } from "vuex";
import PayList from "../../../components/List/ReSendPayList";
import Fee from "../../../components/Fee";
export default {
  props: {
    dataSource: Object,
  },
  components: {
    fee: Fee,
    "pay-list": PayList,
  },
  data() {
    return {};
  },
  mounted() {},
  computed: {
    totalAmount() {
      let result = 0;
      for (let item of this.dataSource.new_pay_item_arr) {
        result += item.amount;
      }
      return result;
    },
  },
  methods: {
    ...mapActions({
      queueSyncLGAction: "queueSyncLG",
    }),
    next() {
      this.$emit("next");
    },
    syncData() {
      this.queueSyncLGAction(this.dataSource)
        .then((res) => {
          this.$Message.success("同步信息成功");
          // if (res.file_url) {
          //     this.$refs.reSendQueueFailModal.open({
          //       pay_item_arr: this.queueForm.pay_item_arr,
          //       file_url: res.file_url,
          //     });
          //   } else {
          //     this.$refs.reSendQueueSuccessModal.open({
          //       pay_item_arr: this.queueForm.pay_item_arr,
          //     });
          //   }
        })
        .catch((error) => {
          this.$Message.error(error);
        });
    },
  },
};
</script>

<style scoped>
.queue-step-page-container {
  display: flex;
  flex-direction: column;
  overflow-y: scroll;
}
.queue-step-header-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 100px;
}
.queue-step-title-container {
  display: flex;
  flex-direction: column;
}
</style>
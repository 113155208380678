<template>
  <div :class="['asset-card-root-container',dataSource.isSelected?'asset-card-selected-border':'']">
    <div class="asset-card-row-container">
      <img :src="icons.assetCardLogo" />
      <div style="font-size: 23px;font-weight: bold;color: #000000;">用工预付款/卡</div>
    </div>
    <div class="asset-card-row-container">
      <div style="display:flex;flex-direction:row;align-self:flex-end;">
        <img :src="icons.bookKeeping" />
        <span
          style="font-size: 12px;font-weight: 500;color: #232323;margin-left:5px;"
        >x 1</span>
      </div>
      <div style="display:flex;flex-direction:column;align-items:flex-end;">
        <div style="font-size: 12px;font-weight: 500;color: #969696;">当前合计余额</div>
        <fee :amount="dataSource.salary_amount" :primarySize="32" :secondarySize="32" />
        <div class="frozen-amount">
          已冻结：
          <fee
            style="color:gray !important;"
            :amount="dataSource.frozen_amount"
            :primarySize="12"
            :secondarySize="12"
          ></fee>
        </div>
        <div class="frozen-amount">
          服务费：
          <fee
            style="color:gray !important;"
            :amount="dataSource.service_amount"
            :primarySize="12"
            :secondarySize="12"
          ></fee>
        </div>
        <div
          style="font-size: 12px;font-weight: 500;color: #969696;"
        >上次更新于：{{ dataSource.update_time }}</div>
      </div>
    </div>
  </div>
</template>

<script>
import * as icon from "../../../common/icon";
import Fee from "../../../components/Fee";
export default {
  components: {
    fee: Fee,
  },
  props: {
    dataSource: Object,
  },
  data() {
    return {
      icons: icon,
    };
  },
};
</script>

<style scoped>
.asset-card-root-container {
  display: flex;
  flex-direction: column;
  width: 400px;
  height: 230px;
  min-height: 230px;
  border-radius: 8px;
  box-shadow: 0 0px 1px #dddddd;
  background-color: #ffffff;
  padding: 10px 30px 0px 30px;
  box-sizing: content-box;
  cursor: pointer;
}
.asset-card-root-container:hover {
  box-shadow: 0 0px 10px #dddddd;
}
.asset-card-row-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}
.asset-card-selected-border {
  border: 3px solid #f8cf7c;
}
</style>
<template>
  <div class="queue-step-page-container">
    <Button
      type="primary"
      icon="ios-arrow-back"
      class="back-button"
      @click="$emit('back')"
    ></Button>
    <span style="font-size: 32px; font-weight: bold; margin-bottom: 50px"
      >小计</span
    >

    <div class="asset-item-container">
      <Row style="margin-bottom: 15px">
        <Col span="9"
          ><div>{{ dataSource.company_name }}</div></Col
        >
        <Col span="5" offset="5"
          ><div style="text-align: right">服务费</div></Col
        >
        <Col span="5"><div style="text-align: right">金额</div></Col>
      </Row>
      <Row style="margin-bottom: 10px">
        <Col span="9"
          ><div>{{ dataSource.name }}</div></Col
        >
        <Col span="5"
          ><div style="text-align: right">
            全部{{ dataSource.new_pay_item_arr.length }}笔
          </div></Col
        >
        <Col span="5"
          ><div style="text-align: right">
            <fee
              :amount="serviceFee"
              :primarySize="18"
              :secondarySize="18"
            /></div
        ></Col>
        <Col span="5"
          ><div style="text-align: right">
            <fee :amount="total" :primarySize="18" :secondarySize="18" /></div
        ></Col>
      </Row>
      <Divider />
      <Row style="margin-bottom: 10px">
        <Col span="9"
          ><div style="text-align: right; font-size: 23px">合计</div></Col
        >
        <Col span="5"
          ><div style="text-align: right; font-size: 23px">
            全部{{ dataSource.new_pay_item_arr.length }}笔
          </div></Col
        >
        <Col span="5"
          ><div style="text-align: right">
            <fee
              :amount="serviceFee"
              :primarySize="23"
              :secondarySize="23"
            /></div
        ></Col>
        <Col span="5"
          ><div style="text-align: right">
            <fee :amount="total" :primarySize="23" :secondarySize="23" /></div
        ></Col>
      </Row>
      <Row style="margin-bottom: 15px">
        <Col span="9"
          ><div style="text-align: right; font-size: 23px">当前余额</div></Col
        >
        <Col span="5" offset="5"
          ><div style="text-align: right">
            <fee
              :amount="cardDetail.service_amount"
              :primarySize="23"
              :secondarySize="23"
            /></div
        ></Col>
        <Col span="5"
          ><div style="text-align: right">
            <fee
              :amount="cardDetail.salary_amount"
              :primarySize="23"
              :secondarySize="23"
            /></div
        ></Col>
      </Row>
      <Row style="margin-bottom: 15px">
        <Col span="9"
          ><div style="text-align: right; font-size: 23px">扣款后余额</div></Col
        >
        <Col span="5" offset="5"
          ><div style="text-align: right">
            <fee2
              :amount="cardDetail.service_amount - serviceFee"
              :primarySize="23"
              :secondarySize="23"
            /></div
        ></Col>
        <Col span="5"
          ><div style="text-align: right">
            <fee2
              :amount="cardDetail.salary_amount - total"
              :primarySize="23"
              :secondarySize="23"
            /></div
        ></Col>
      </Row>
    </div>

    <Button
      type="primary"
      @click="next"
      size="large"
      style="width: 300px; margin-top: 50px; flex-shrink: 0"
      >👌 确认完毕，输入密码完成最后支付</Button
    >

    <div style="min-height: 80px"></div>
  </div>
</template>

<script>
import { mapActions, mapState, mapGetters } from "vuex";
import QueueItemCard from "../Item/QueueItemCard";
import AssetCard from "../../Asset/Item/AssetCard";
import Fee from "../../../components/Fee";
import Fee2 from "../../../components/Fee2";
import moment from "moment";
import QueueAssetItemVue from "../Item/QueueAssetItem.vue";
export default {
  props: {
    dataSource: Object,
  },
  components: {
    fee: Fee,
    fee2: Fee2,
    "asset-card": AssetCard,
    "queue-item-card": QueueItemCard,
    "queue-asset-item": QueueAssetItemVue,
  },
  data() {
    return {
      cardDetail: {},
      serviceFee: 0,
    };
  },
  mounted() {
    this.queueGetServiceAmountAction({
      employer_id: this.dataSource.employer_id,
      salary_amount: this.total,
    })
      .then((res) => {
        this.serviceFee = res.service_amount;
      })
      .catch((error) => {
        this.$Message.error(error);
      });

    this.assetGetCardDetailByEmployerIdAction({
      employer_id: this.dataSource.employer_id,
      company_id: this.dataSource.company_id,
    })
      .then((res) => {
        this.cardDetail = res;
      })
      .catch((error) => {
        this.$Message.error(error);
      });
  },
  computed: {
    total() {
      let salary_amount = 0;
      this.dataSource.new_pay_item_arr.map((item) => {
        salary_amount += item.amount;
        return item;
      });
      return salary_amount;
    },
  },
  methods: {
    ...mapActions({
      queueGetServiceAmountAction: "queueGetServiceAmount",
      assetGetCardDetailByEmployerIdAction: "assetGetCardDetailByEmployerId",
    }),
    next() {
      let flag = true;
      // 需要支付金额大于现有余额则报错
      if (this.total > this.cardDetail.salary_amount) {
        flag = false;
      }
      if (flag) {
        this.$parent.$parent.showPhone();
      } else {
        this.$Message.error("余额不足，无法创建。");
      }
    },
  },
};
</script>

<style scoped>
.queue-step-page-container {
  display: flex;
  flex-direction: column;
  overflow-y: scroll;
}
.asset-item-container {
  display: flex;
  flex-direction: column;
  flex-shrink: 0;
  padding: 20px;
  border-radius: 10px;
  background-color: #fff;
  margin-top: 50px;
  margin-bottom: 20px;

  font-size: 15px;
  font-weight: bold;
}
</style>
<template>
  <div>
    <Select
      size="large"
      :value="value"
      @on-change="change"
      @on-open-change="onOpenChange"
    >
      <my-avatar
        :name="selectedItem.name"
        :img_url="selectedItem.img_url"
        :size="32"
        slot="prefix"
      />
      <Option
        :value="item.contact_id"
        :label="item.name"
        v-for="item in dataSource"
        :key="'contact-' + item.contact_id"
      >
        <div style="display: flex; flex-direction: row; align-items: center">
          <my-avatar
            v-if="isShowAvatar"
            :name="item.name"
            :img_url="item.img_url"
            :size="32"
            style="margin-right: 10px"
          />
          <div style="flex: 1; display: flex; flex-direction: column">
            <div>{{ item.name }}</div>
            <div style="display: flex; flex-direction: row">
              <div
                style="display: flex; flex-direction: row; margin-right: 10px"
              >
                <Icon :size="12" type="md-phone-portrait" />
                <div style="margin-left: 5px; font-size: 12px; color: #5f5f5f">
                  {{ item.phone }}
                </div>
              </div>
              <div class="my-select-people-row">
                <Icon :size="12" type="md-mail" />
                <div style="margin-left: 5px; font-size: 12px; color: #5f5f5f">
                  {{ item.email }}
                </div>
              </div>
            </div>
          </div>
        </div>
      </Option>
    </Select>
  </div>
</template>

<script>
import MyAvatar from "../MyAvatar";
export default {
  model: {
    prop: "value",
    event: "on-change",
  },
  components: {
    "my-avatar": MyAvatar,
  },
  props: {
    value: Number,
    dataSource: Array,
  },
  data() {
    return {
      selectedItem: {
        name: "",
        img_url: null,
      },
      isShowAvatar: false,
    };
  },
  methods: {
    change(value) {
      this.selectedItem = this.dataSource.find(
        (item) => item.contact_id == value
      );
      this.$emit("on-change", value);
    },
    onOpenChange(value) {
      if (value) {
        setTimeout(() => {
          this.$nextTick(() => {
            this.isShowAvatar = true;
          });
        }, 100);
      } else {
        this.isShowAvatar = false;
      }
    },
  },
};
</script>

<style scoped>
.my-select-people-title {
  margin-bottom: 15px;
  color: #646464;
  font-weight: 500px;
  font-size: 12px;
}
.my-select-people-row {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  min-width: 60%;
}
</style>
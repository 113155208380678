<template>
  <div class="queue-step-root-container">
    <Button type="primary" icon="ios-arrow-back" class="back-button" @click="$emit('back')"></Button>
    <span style="font-size:32px;font-weight:bold">您绑定的账户资金不足</span>
    <div class="queue-step-tip">
      <p>👋 我们注意到您选中的账户资产不足，无法立刻完成支付。您可以继续点击下一步，我们会为您将不足的金额部分生成提示看板</p>
    </div>
    <div class="queue-step-row">
      <Button
        size="large"
        type="text"
        style="color:#416895; margin-right:30px; width:200px;"
        @click="$emit('close')"
      >放弃该处理队列</Button>
      <Button
        size="large"
        type="primary"
        style="color:#416895；margin-right:30px;width:200px;"
        @click="$emit('save')"
      >保存该处理队列</Button>
    </div>
  </div>
</template>

<script>
export default {

};
</script>

<style scoped>
.queue-step-root-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 200px;
}
.queue-step-tip {
  display: flex;
  flex-direction: column;
  border-radius: 10px;
  padding: 20px;
  max-width: 600px;
  background-color: #ecdbdb;
  margin-top: 30px;
}
.queue-step-row {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 100px;
}
</style>
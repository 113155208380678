<template>
  <div class="queue-detail-not-enough-container">
    <span
      style="
        color: #fff;
        font-size: 20px;
        font-weight: bold;
        margin-bottom: 20px;
      "
      >资产不足提醒</span
    >
    <p style="color: #fff">
      您的游用工卡当前资产余额不足以完成支付，不足金额为
      <fee
        style="display: inline-block; color: #fff"
        :amount="dataSource.total_amount"
        :primarySize="15"
        :secondarySize="15"
      />
      (含服务费)
    </p>
  </div>
</template>

<script>
import Fee from "../../../components/Fee";
export default {
  props: {
    dataSource: Object,
  },
  components: {
    fee: Fee,
  },
};
</script>

<style scoped>
.queue-detail-not-enough-container {
  padding: 20px 30px;
  background-color: #00b38d;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
}
</style>
<template>
  <div class="queue-root-container">
    <div v-if="!isNeedCode" class="queue-inner-container">
      <div class="queue-header-container">
        <span class="page-title">处理队列</span>
        <div class="queue-tools-container">
          <Icon
            type="md-archive"
            size="20"
            color="#3385A6"
            style="margin-right: 5px"
          />
          <span
            style="
              color: #3385a6;
              font-size: 15px;
              margin-right: 20px;
              cursor: pointer;
            "
            @click="showReceiveModal"
            >接收到的付款计划</span
          >
          <Icon
            type="ios-checkmark-circle"
            size="20"
            color="#3385A6"
            style="margin-right: 5px"
          />
          <span
            style="color: #3385a6; font-size: 15px; cursor: pointer"
            @click="showFinishModal"
            >已完成的付款队列</span
          >
        </div>
      </div>

      <div class="queue-fast-tools-container">
        <div class="queue-btn" @click="create">
          <Icon
            type="ios-card"
            size="18"
            color="#3385A6"
            style="margin-right: 5px"
          />
          <span>完成付款出账</span>
        </div>
      </div>

      <div class="queue-notification-container">
        <span
          v-if="notificationList.length == 0 && canPay"
          style="font-size: 12px"
          >🎉 恭喜您，没有任何未完成的任务</span
        >
        <div style="display: flex; flex-direction: row">
          <queue-event-item
            v-for="(n, index) in errorCount"
            :key="index"
            :data-source="notificationList[n - 1]"
            @refresh-error-list="refreshErrorList"
            @refresh-queue-list="refresh"
          />
        </div>
        <div v-if="isShowMore" class="queue-notification-more-container">
          <span style="font-size: 15px; font-weight: 500; color: #292929"
            >其它错误</span
          >
          <span
            style="
              font-size: 12px;
              font-weight: 500;
              color: #5e5e5e;
              margin-top: 10px;
            "
            >请先处理已显示的错误,剩余错误{{
              notificationList.length - 7
            }}条</span
          >
        </div>
        <div
          style="display: flex; flex-direction: row"
          v-if="!canPay && !isComplete"
        >
          <queue-not-enough
            style="height: 210px; width: 210px; margin-right: 20px"
            :data-source="notEnough"
          />
        </div>
        <div style="width: 1px; height: 1px"></div>
      </div>

      <span style="font-size: 23px; font-weight: bold; margin-top: 50px"
        >已设置支付日期的</span
      >

      <div class="queue-main-container">
        <div class="queue-item-container">
          <queue-item
            v-for="item in queueList"
            :key="item.queue_id"
            :data-source="item"
            @close="refresh"
            @click.native="goDetail(item)"
          />
        </div>
      </div>
    </div>
    <div v-else class="queue-inner-container">
      <span class="page-title">付款处理队列</span>
      <div class="queue-code-container">
        <div class="queue-code-img-container">
          <img :src="icons.code" />
        </div>
        <div class="queue-code-form">
          <span style="font-size: 23px; font-weight: bold"
            >🔐您正在进行最后的付款处理，登录该页面之前，请您输入敏感操作密码</span
          >
          <my-input
            style="margin-top: 20px"
            size="large"
            placeholder="8位密码"
            ref="formCode"
            type="password"
            title="输入敏感操作密码"
            v-model="code"
            :validate="codeValidate"
          />
          <Button
            @click="submitCode"
            type="primary"
            size="large"
            style="width: 100px; margin-top: 20px"
            >确认</Button
          >
        </div>
      </div>
    </div>
    <create-queue-modal ref="createQueueModal" @close="refresh" />
    <pay-finish-modal ref="payFinishModal" />
    <receive-record-modal ref="receiveRecordModal" />
  </div>
</template>

<script>
import { mapActions } from "vuex";
import moment from "moment";
import * as icon from "../../common/icon";
import Fee from "../../components/Fee";
import QueueItem from "./Item/QueueItem";
import MyInput from "../../components/Form/MyInput";
import PayFinishModal from "./Modal/PayFinishModal";
import SliderTabbar from "../../components/SlideTabbar";
// import CreateQueueModal from "./Modal/CreateQueueModal";
import CreateQueueModal from "./Modal/CreateQueueNewModal";
import QueueNotEnough from "./Panel/QueueNotEnough";
import QueueEventItem from "./Item/QueueEventItem";
import ReceiveRecordModal from "./Modal/ReceiveRecordModal";
export default {
  components: {
    fee: Fee,
    "my-input": MyInput,
    "queue-item": QueueItem,
    "slider-tabbar": SliderTabbar,
    "pay-finish-modal": PayFinishModal,
    "queue-not-enough": QueueNotEnough,
    "create-queue-modal": CreateQueueModal,
    "receive-record-modal": ReceiveRecordModal,
    "queue-event-item": QueueEventItem,
  },
  data() {
    return {
      icons: icon,

      isNeedCode: false,
      canPay: false,
      isComplete: false,

      code: "",
      notificationList: [],
      queueList: [],

      notEnough: {
        // queue_id: 0,
        total_amount: 0,
      },
    };
  },
  mounted() {
    this.refresh();
  },
  computed: {
    errorCount() {
      return this.notificationList.length <= 7
        ? this.notificationList.length
        : 7;
    },
    isShowMore() {
      return this.notificationList.length > 7;
    },
  },
  methods: {
    ...mapActions({
      queueGetListAction: "queueGetList",
      queueCheckPwdAction: "queueCheckPwd",
      assetGetBalanceAction: "assetGetBalance",
      queueGetErrorListAction: "queueGetErrorList",
    }),
    submitCode() {
      Promise.all([this.$refs.formCode.doValidate()])
        .then(() => {
          this.queueCheckPwdAction({
            employer_id: localStorage.getItem("employer_id"),
            pwd: this.code,
          })
            .then((res) => {
              localStorage.setItem("code", this.code);
              this.isNeedCode = false;
            })
            .catch((error) => {
              this.isNeedCode = true;
              this.$Message.error(error);
            });
        })
        .catch(() => {
          this.$Message.warning("表单中有错误项，请检查后修改并重新提交");
        });
    },
    /** 表单验证 */
    codeValidate(value) {
      return new Promise((resolve, reject) => {
        if (this.code == "") {
          reject("请填写8位数字密码");
        } else {
          resolve();
        }
      });
    },
    goDetail(item) {
      this.$router.push({
        name: "queue-detail",
        params: {
          id: item.queue_id,
        },
      });
    },
    create() {
      this.$refs.createQueueModal.open();
    },
    showFinishModal() {
      this.$refs.payFinishModal.open();
    },
    showReceiveModal() {
      this.$refs.receiveRecordModal.open();
    },
    refresh() {
      if (localStorage.getItem("code")) {
        this.queueCheckPwdAction({
          employer_id: localStorage.getItem("employer_id"),
          pwd: localStorage.getItem("code"),
        })
          .then((res) => {
            this.isNeedCode = false;
          })
          .catch((error) => {
            this.isNeedCode = true;
            this.$Message.error(error);
          });
      } else {
        this.isNeedCode = true;
      }

      // this.queueGetListAction({
      //   employer_id: localStorage.getItem("employer_id"),
      // })
      //   .then((res) => {
      //     this.canPay = true;
      //     for (let item of res) {
      //       item.pay_plan_id_arr = JSON.parse(item.pay_plan_id_arr);
      //       item.pay_time = moment(item.pay_time).format("YYYY/MM/DD HH:mm");
      //       if (res.state == 1 && false) {
      //         this.canPay = false;
      //       }
      //     }
      //     this.queueList = res;
      //   })
      //   .catch((error) => {
      //     this.$Message.error(error);
      //   });

      Promise.all([
        this.assetGetBalanceAction({
          employer_id: localStorage.getItem("employer_id"),
        }),
        this.queueGetListAction({
          employer_id: localStorage.getItem("employer_id"),
        }),
      ]).then((values) => {
        let balance = values[0];

        this.canPay = true;

        this.notEnough.total_amount = 0;
        for (let item of values[1]) {
          item.pay_plan_id_arr = JSON.parse(item.pay_plan_id_arr);
          item.pay_time = moment(item.pay_time).format("YYYY/MM/DD HH:mm");
          // if (item.state == 1 && balance.salary_amount < item.salary_amount) {
          //   // this.notEnough.queue_id = item.queue_id;
          //   this.notEnough.total_amount +=
          //     item.salary_amount +
          //     item.service_amount -
          //     (balance.salary_amount + balance.service_amount);
          //   this.canPay = false;
          // }
        }
        this.queueList = values[1];
      });

      this.refreshErrorList();
    },
    refreshErrorList() {
      this.queueGetErrorListAction({
        employer_id: localStorage.getItem("employer_id"),
      })
        .then((res) => {
          for (let item of res) {
            item.error_info = JSON.parse(item.error_info);
            item.invalid = false;
            if (Array.isArray(item.error_info)) {
              for (let i of item.error_info) {
                i.client_name = i.client_user_name;
              }
            } else {
              item.error_info = [
                {
                  client_name: item.error_info.name,
                },
              ];
              item.invalid = true;
            }
          }
          this.notificationList = res;
        })
        .catch((error) => {
          console.error(error);
          this.$Message.error(error);
        });
    },
  },
};
</script>

<style scoped>
.queue-root-container {
  display: flex;
  flex-direction: column;
  padding-top: 30px;
  padding-left: 15px;
  padding-right: 150px;
  background-color: #f9f9f9;
}
.queue-inner-container {
  flex: 1;
  display: flex;
  flex-direction: column;
  overflow: hidden;
}
.queue-header-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.queue-tools-container {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.queue-notification-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 20px;
  background-color: #f3f3f3;
  margin-top: 40px;
  border-radius: 10px;
  overflow-x: scroll;
}
.queue-notification-more-container {
  display: flex;
  flex-direction: column;
  width: 355px;
  height: 210px;
  padding: 15px;
  border-radius: 8px;
  background-color: #ffffff;
  margin-right: 20px;
  min-width: 355px;
}
.queue-fast-tools-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 10px;
}
.queue-btn {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 10px 20px;
  border-radius: 10px;
  background-color: #e3eafa;
  font-size: 12px;
  font-weight: bold;
  margin-right: 20px;
  cursor: pointer;
}
.queue-main-container {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  overflow: hidden;
  background-color: #fff;
  border-radius: 10px;
  margin-top: 30px;
  margin-bottom: 50px;
  padding-top: 10px;
  padding-bottom: 10px;
}
.queue-item-container {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  overflow-y: scroll;
}
.queue-code-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  background-color: #fff;
  border-radius: 13px;
  padding: 20px 50px;
  margin-top: 30px;
}
.queue-code-img-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  flex: 1;
}
.queue-code-form {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  overflow: hidden;
  background-color: #fff;
  border-radius: 10px;
  margin-top: 30px;
  padding-bottom: 10px;
}
</style>
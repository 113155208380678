<template>
  <div class="queue-step-page-container">
    <Button
      type="primary"
      icon="ios-arrow-back"
      class="back-button"
      @click="$emit('back')"
    ></Button>
    <span style="font-size: 32px; font-weight: bold; margin-bottom: 50px"
      >请完成付款设置</span
    >

    <!-- <div
      class="queue-step-page2-plan-rename-container"
      v-for="(item, index) in dataSource"
      :key="index"
    > -->
    <div style="font-weight: bold; min-width: 100px">
      原队列名称：{{ dataSource.old_name }}，队列重命名
    </div>
    <my-input
      style="width: 500px"
      ref="formName"
      size="large"
      title=""
      v-model="queueInfo.name"
    />
    <!-- </div> -->

    <span
      style="
        margin-top: 40px;
        margin-bottom: 20px;
        font-size: 12px;
        font-weight: bold;
      "
      >选择稍后接收验证码的联系人</span
    >
    <my-select-people
      style="width: 300px"
      v-model="queueInfo.company_contact_id"
      :dataSource="contactList"
    />

    <span
      style="
        margin-bottom: 20px;
        margin-top: 50px;
        font-size: 12px;
        font-weight: bold;
      "
      >您可以选择立即完成支付或者设置一个稍后的付款日期</span
    >
    <RadioGroup v-model="queueInfo.type">
      <Radio :label="1">
        <span>立即支付</span>
      </Radio>
      <Radio :label="2">
        <span>设定付款日期</span>
      </Radio>
    </RadioGroup>

    <my-datepicker
      v-if="queueInfo.type == 2"
      ref="formDate"
      title="付款日期"
      v-model="queueInfo.pay_time"
      style="width: 250px; margin-top: 20px"
    />

    <my-textarea
      ref="formMore"
      title="添加备注信息"
      :rows="4"
      v-model="queueInfo.more"
      style="margin-top: 50px; margin-bottom: 80px; width: 500px"
    />

    <div class="tools-container">
      <Button type="primary" @click="next" size="large"
        >确认无误，下一步</Button
      >
    </div>
  </div>
</template>

<script>
import { mapActions, mapState, mapGetters } from "vuex";

import MyInput from "../../../components/Form/MyInput";
import MyTextArea from "../../../components/Form/MyTextArea";
import MyDatePicker from "../../../components/Form/MyDatePicker";
import MySelectPeople from "../../../components/Form/MySelectPeople";
import moment from "moment";
export default {
  props: {
    dataSource: Object,
    companyId: Number,
    employerCardId: Number,
  },
  components: {
    "my-input": MyInput,
    "my-datepicker": MyDatePicker,
    "my-textarea": MyTextArea,
    "my-select-people": MySelectPeople,
  },
  data() {
    return {
      contact_id: 0,

      contactList: [],
      payList: [],

      queueInfo: {
        company_contact_id: 0,
        pay_time: new Date(),
        more: "",
        type: 1,
        name: "",
      },
    };
  },
  mounted() {
    this.queueInfo.company_contact_id = this.dataSource.company_contact_id;
    this.queueInfo.pay_time = this.dataSource.pay_time;
    this.queueInfo.more = this.dataSource.more;
    this.queueInfo.type = this.dataSource.type;
    this.queueInfo.name = this.dataSource.name;

    this.companyGetContactListByCompanyIdsAction({
      employer_id: localStorage.getItem("employer_id"),
      company_id_arr: [this.dataSource.company_id],
    })
      .then((res) => {
        this.contactList = res;
      })
      .catch((error) => {
        this.$Message.error(error);
      });
  },
  methods: {
    ...mapActions({
      companyGetContactListByCompanyIdsAction:
        "companyGetContactListByCompanyIds",
    }),
    next() {
      if (this.queueInfo.company_contact_id == 0) {
        this.$Message.error("请选择联系人");
        return;
      }

      let data = Object.assign(this.queueInfo, {
        pay_time: moment(this.queueInfo.pay_time).toDate(),
      });
      this.$emit("next", data);
    },
  },
};
</script>

<style scoped>
.queue-step-page-container {
  display: flex;
  flex-direction: column;
  overflow-y: scroll;
}
.queue-step-page2-plan-rename-container {
  display: flex;
  flex-direction: column;
}
</style>
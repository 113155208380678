<template>
  <Modal v-model="isShowModal" footer-hide :closable="false">
    <div v-if="isShowModal" class="queue-modal-root-container">
      <!-- <my-avatar :name="card.name" :img_url="card.img_url" :size="50" style="margin-right: 10px;"></my-avatar>
      <span style="font-size:15px;font-weight:bold;margin-top:20px;">{{card.name}}</span> -->
      <fee
        style="margin-top: 20px"
        :amount="totalAmount"
        :primarySize="23"
        :secondarySize="18"
      />
      <Divider />
      <div class="queue-modal-column" style="margin-bottom: 30px">
        <!-- <span style="font-size:23px;font-weight:bold;margin-bottom:20px;">验证码已发送至手机</span> -->
        <span style="font-size: 15px; margin-bottom: 50px">{{
          contact.phone
        }}</span>

        <div class="queue-modal-row">
          <Input
            v-model="code"
            size="large"
            placeholder="输入您接收到的验证码"
          />
          <Button
            @click="send"
            type="text"
            size="large"
            style="margin-left: 10px"
            v-if="!isSendSMS"
            >发送短信</Button
          >
          <span v-else style="width: 200px; margin-left: 20px"
            >{{ duration }}秒</span
          >
        </div>
      </div>
      <Button
        size="large"
        shape="circle"
        type="primary"
        style="width: 150px; margin-top: 30px"
        @click="next"
        >输入密码</Button
      >
    </div>
  </Modal>
</template>

<script>
import { mapActions, mapState, mapGetters } from "vuex";
import Fee from "../../../components/Fee";
import MyAvatar from "../../../components/MyAvatar";
import * as icon from "../../../common/icon";
export default {
  props: {
    dataSource: Object,
  },
  components: {
    fee: Fee,
    "my-avatar": MyAvatar,
  },
  data() {
    return {
      icons: icon,
      isShowModal: false,

      // card: {
      //   name: "",
      //   img_url: null,
      // },
      code: "",
      duration: 60,
      _interval: null,
      isSendSMS: false,
      payList: [],
      contact: {
        phone: "",
      },
    };
  },
  mounted() {},
  computed: {
    totalAmount() {
      let result = 0;
      for (let item of this.payList) {
        result += item.amount;
      }
      return result;
    },
  },
  methods: {
    ...mapActions({
      planGetPayListAction: "planGetPayList",
      employerGetContactByIdAction: "employerGetContactById",
      smsSendSMSAction: "smsSendSMS",
      smsVerifySMSAction: "smsVerifySMS",
    }),
    open(form) {
      this.code = "";
      this.isShowModal = true;

      if (form) {
        this.payList = form.new_pay_item_arr;
      } else {
        this.planGetPayListAction({
          employer_id: localStorage.getItem("employer_id"),
          pay_plan_id_arr: this.dataSource.pay_plan_id_arr,
        })
          .then((res) => {
            this.payList = res;
          })
          .catch((error) => {
            this.$Message.error(error);
          });
      }

      this.employerGetContactByIdAction({
        employer_id: localStorage.getItem("employer_id"),
        contact_id: this.dataSource.company_contact_id,
      })
        .then((res) => {
          this.contact = res;
          // this.send();
        })
        .catch((error) => {
          this.$Message.error(error);
        });
    },
    close() {
      this.isShowModal = false;
      if (this._interval) {
        clearInterval(this._interval);
      }
    },
    send() {
      if (this.isSendSMS) {
        return;
      }
      this.isSendSMS = true;

      this.smsSendSMSAction({
        phone: this.contact.phone,
      })
        .then((res) => {
          this._interval = setInterval(() => {
            this.duration--;
            if (this.duration == 0) {
              this.isSendSMS = false;
              this.duration = 60;
              clearInterval(this._interval);
            }
          }, 1000);
        })
        .catch((error) => {
          this.isSendSMS = false;
          this.$Message.error(error);
        });
    },
    next() {
      this.smsVerifySMSAction({
        phone: this.contact.phone,
        sms_code: this.code,
      })
        .then((res) => {
          if (res) {
            this.$emit("next");
          } else {
            this.$Message.error("验证码错误");
          }
        })
        .catch((error) => {
          this.$Message.error(error);
        });
    },
  },
};
</script>

<style scoped>
.queue-modal-root-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 50px 100px;
}
.queue-modal-column {
  display: flex;
  flex-direction: column;
  align-self: flex-start;
}
.queue-modal-row {
  display: flex;
  flex-direction: row;
  align-items: center;
}
</style>
<template>
  <Modal v-model="modalVisible" width="360">
    <p slot="header" class="delete-modal-header">
      <Icon type="information-circled"></Icon>
      <span>{{title}}</span>
    </p>
    <div class="delete-modal-main-container">
      <slot>
        <p>{{desc}}</p>
      </slot>
    </div>
    <div slot="footer">
      <Button type="error" size="large" long :loading="deleteLoading" @click="deleteConfirm">删除</Button>
    </div>
  </Modal>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      default: "删除确认"
    },
    desc: {
      type: String,
      default: "确认删除吗？"
    }
  },
  data() {
    return {
      modalVisible: false,
      deleteLoading: false
    };
  },
  methods: {
    open(dataSource) {
      this.dataSource = dataSource;
      this.modalVisible = true;
    },
    deleteConfirm() {
      this.deleteLoading = true;
      this.$emit("confirm", this.dataSource, this.finish);
    },
    finish() {
      this.deleteLoading = false;
      this.modalVisible = false;
    }
  }
};
</script>

<style scoped>
.delete-modal-header {
  color: #f60;
  text-align: center;
}
.delete-modal-main-container {
  text-align: center;
  padding: 20px;
}
</style>